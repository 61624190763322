<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="id ? $t('system.edit_vacation_request') : $t('system.create_vacation_request')"
      :visible.sync="dialogVisible"
      @closed="close"
      class="vacation-request-form"
      destroy-on-close
  >
    <el-form
        :label-position="ifHasPermission(['wo-leave-create-all']) ? 'top' : ''"
        :size="fieldsSize"
    >
      <div class="row">
        <div class="col-lg-6">
          <el-form-item
              :label="$t('system.date_renge')"
              required
          >
            <div :class="{'validate-error': !!getBackendErrors('from')}">
              <el-date-picker
                  :class="{
                  'w-100': ifHasPermission(['wo-leave-create-all']),
                }"
                  :picker-options="pickerOptions"
                  type="daterange"
                  v-model="form.dateRange"
              />
            </div>
            <div
                class="el-form-item__error"
                v-if="getBackendErrors('from')"
            >
              {{ $t(getBackendErrors('from')[0]) }}
            </div>
          </el-form-item>
        </div>
        <div
            class="col-lg-6"
            v-if="ifHasPermission(['wo-leave-create-all'])"
        >
          <el-form-item
              :label="$t('system.user')"
              required
          >
            <el-select
                :class="{'validate-error': !!getBackendErrors('user_id')}"
                class="w-100"
                filterable
                v-model="form.user_id"
            >
              <el-option
                  :key="user.id"
                  :label="user.name"
                  :value="user.id"
                  v-for="user in users"
              />
            </el-select>
            <div
                class="el-form-item__error"
                v-if="getBackendErrors('user_id')"
            >
              {{ $t(getBackendErrors('user_id')[0]) }}
            </div>
          </el-form-item>
        </div>
        <div class="col-sm-12">
          <el-form-item :label="$t('system.reason')">
            <el-input
                :rows="3"
                type="textarea"
                v-model="form.reason"
            />
          </el-form-item>
        </div>
      </div>
    </el-form>
    <span
        class="dialog-footer"
        slot="footer"
    >
      <el-button
          :loading="saveLoading"
          :size="fieldsSize"
          @click="save"
          type="primary"
      >
        {{ $t('system.save') }}
      </el-button>
      <el-button
          :disabled="saveLoading"
          :size="fieldsSize"
          @click="close"
      >
        {{ $t('system.cancel') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import {mapGetters} from 'vuex';
import moment from 'moment';
import axios from 'axios';
import notification from '../../notification/notify';

export default {
  name: 'VacationRequestForm',
  props: ['id'],
  data() {
    return {
      users: [],
      saveLoading: false,
      fieldsSize: 'small',
      dialogVisible: true,
      form: {
        dateRange: [],
        reason: '',
      },
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return moment(time) < moment().subtract(1, 'day');
        },
      },
      backendErrors: {},
    };
  },
  beforeMount() {
    if (this.ifHasPermission(['wo-leave-create-all'])) {
      this.fetchUsers();
    }
    if (this.id) {
      this.fetchVacationRequest();
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  methods: {
    async fetchUsers() {
      try {
        const {data} = await axios.get(`workorders/users`);
        this.users = data;
        if (this.users.find(user => user.id === this.user.id)) {
          this.form.user_id = this.user.id;
        }
      } catch (e) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_users_list'),
            'error');
      }
    },
    async fetchVacationRequest() {
      try {
        const {data} = await axios.get(`workorders/leave/${this.id}`);
        this.form = data.data;
      } catch (e) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load'),
            'error');
      }
    },
    async save() {
      this.saveLoading = true;
      try {
        const formClone = {
          ...this.form,
          from: this.form.dateRange[0] ? moment(this.form.dateRange[0]).format('YYYY-MM-DD') : null,
          to: this.form.dateRange[1] ? moment(this.form.dateRange[1]).format('YYYY-MM-DD') : null,
        };
        delete formClone.dateRange;
        let method = 'post';
        let url = 'workorders/leave';
        if (this.id) {
          method = 'put';
          url = `workorders/leave/${this.id}`;
        }
        const {data} = await axios[method](url, formClone);
        await this.$router.push({name: 'VacationRequest', params: {id: data.id ? data.id : this.id}});
      } catch (error) {
        this.backendErrors = error.response.data;
      } finally {
        this.saveLoading = false;
      }
    },
    close() {
      // if (this.id) {
      this.$router.push({name: 'VacationRequestsList'});
      // }
    },
    getBackendErrors(field) {
      return this.backendErrors[field];
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
>
.vacation-request-form {
  .el-form {
    .el-form-item__label {
      padding: 0;
      /*margin-bottom: 0;*/
    }
  }
}
</style>
